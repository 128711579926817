export default {
    translation: {
        pageHeading: 'DNSモニタリングのサインアップ',
        header: {
            altText: 'Cisco Umbrellaを使用',
        },
        sidebar: {
            heading: '企業が無料で利用できる、リアルタイムのセキュリティ可視化機能を備えた、非常に信頼性の高い高速再帰DNS。',
            subheading: '30秒で使用を開始できます。クレジットカードや電話は不要です。',
            list: {
                heading: '含まれる内容',
                items: [
                    {
                        heading: 'インターネット全体の可視化',
                        body: 'すべてのインターネットアクティビティをリアルタイムでログに記録し、セキュリティの脅威(マルウェアやフィッシングなど)と80種類のWebコンテンツによって分類します。',
                    },
                    {
                        heading: '信頼性の高い高速DNS',
                        body: '2006年以来100%の稼働時間を維持している最速のDNSリゾルバで、リアルタイムの可視化とアクティビティレポートも提供します。',
                    },
                    {
                        heading: '簡単な展開',
                        body: '組み込まれているオンボーディングガイドと統合により、ほとんどの組織で30分以内にDNSモニタリングを展開できます。',
                    },
                ],
            },
            personal: {
                altText: 'DNSモニタリングの構築',
                body: 'DNSモニタリングにより、ネットワーク上のアクティビティを可視化できます。アクティビティのブロックを検討されている場合は、<1>Umbrellaを14日間無料でお試しください。</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: '名',
                    errors: {
                        empty: '[名]フィールドは必須です。',
                        invalid: '[名]フィールドの入力が無効です。',
                    },
                },
                lastName: {
                    label: '姓',
                    errors: {
                        empty: '[姓]フィールドは必須です。',
                        invalid: '[姓]フィールドの入力が無効です。',
                    },
                },
                emailAddress: {
                    label: '電子メールアドレス',
                    errors: {
                        empty: '[電子メールアドレス]フィールドは必須です。',
                        invalid: '[電子メールアドレス]フィールドの入力が無効です。',
                    },
                },
                phoneNumber: {
                    label: '電話番号',
                    errors: {
                        empty: '[電話番号]フィールドは必須です。',
                        invalid: '[電話番号]フィールドの入力が無効です。',
                    },
                },
                country: {
                    label: '国',
                    errors: {
                        empty: '[国]フィールドは必須です。',
                    },
                },
                companyName: {
                    label: '会社名',
                    errors: {
                        empty: '[会社名]フィールドは必須です。',
                        invalid: '[会社名]フィールドの入力が無効です。',
                    },
                },
                createPassword: {
                    label: 'パスワードの作成',
                    errors: {
                        empty: '[パスワードの作成]フィールドは必須です。',
                        invalid: '[パスワードの作成]フィールドの入力が無効です。',
                    },
                    rulesMessage: 'パスワードには、少なくとも1つの数字、1つの小文字、 1つの大文字、および1つの特殊文字(!、@、# など)が含まれている必要があります。また、ユーザ名の一部を 含めることはできず、8文字以上である必要があります。',
                },
                confirmPassword: {
                    label: 'パスワードの確認',
                    errors: {
                        empty: '[パスワードの確認]フィールドは必須です。',
                        newPasswordRequired: '確認されたパスワードの前に、有効な新しいパスワードを入力する必要があります。',
                        mismatch: '[パスワードの確認]フィールドは[パスワードの作成]フィールドと一致している必要があります。',
                    },
                },
                russiaCheckbox: {
                    label: 'このアカウントがビジネス目的のものであることを確認します。',
                    errors: {
                        invalid: '本製品、サービス、または無料トライアルは、ロシアでの民生用には使用できません。',
                    },
                },

            },
            buttonLabel: 'DNSモニタリングを今すぐ実装する',
            errorMessage: 'エラーが発生しました。この電子メールがすでにユーザに属している場合は、<1>ログインして</1>再送信してください。また、DNSモニタリング組織は1つしか持てないことにも注意してください。それ以外の場合は、<3>サポートに連絡してください。</3>.',
            optInMessage: 'はい、Cisco UmbrellaとCisco Cloudlockおよび関連サービスに関するニュースレター、更新、およびプロモーションの受け取りを希望します。また、自分の設定をいつでも変更できることを理解しています。',
            optOutMessage: 'いいえ、Cisco UmbrellaとCisco Cloudlockおよび関連サービスに関するニュースレター、更新、およびプロモーションの受け取りは希望しません。また、自分の設定をいつでも変更できることを理解しています。',
        },
        finePrint: '<0> [DNSモニタリングを今すぐ実装する]をクリックすると、Umbrellaの</0><1>利用規約</1><2>と</2><3>プライバシーポリシー</3><4>に同意したことになるとともに、個人情報が処理のためにお客様の居住国以外の国に転送される場合があることとその国のデータ保護に関する基準が居住国とは異なる場合があることを了承したことになります。</4>',
        footer: {
            menu: [
                'プライバシーポリシー',
                'サイトマップ',
                '利用規約',
            ],
        },
    },
};
