/* eslint-disable no-useless-escape */
export default {
    translation: {
        pageHeading: 'Für die DNS-Überwachung anmelden',
        header: {
            altText: 'Umbrella von Cisco',
        },
        sidebar: {
            heading: 'Extrem zuverlässiges und schnelles rekursives DNS für Ihr Unternehmen – KOSTENLOS mit Echtzeit-Sicherheitstransparenz.',
            subheading: 'Einsatzbereit in 30 Sekunden Keine Kreditkarte oder Anruf erforderlich',
            list: {
                heading: 'Leistungsumfang',
                items: [
                    {
                        heading: 'Internetweite Transparenz',
                        body: 'Alle Internetaktivitäten werden in Echtzeit protokolliert und nach Sicherheitsbedrohungen (z.B. Malware & Phishing) sowie 80 Arten von Webinhalten kategorisiert.',
                    },
                    {
                        heading: 'Schnelles und zuverlässiges DNS',
                        body: 'Seit 2006 haben wir eine Verfügbarkeit von 100 % und sind der schnellste DNS-Resolver, der Echtzeittransparenz und Aktivitätsberichte bietet',
                    },
                    {
                        heading: 'Einfache Bereitstellung',
                        body: 'Dank des integrierten Onboarding-Leitfadens und der Integrationen stellen die meisten Unternehmen DNS-Überwachung in weniger als 30 Minuten bereit.',
                    },
                ],
            },
            personal: {
                altText: 'DNS-Überwachung aufbauen',
                body: 'Die DNS-Überwachung liefert einen Überblick über die Aktivitäten in Ihrem Netzwerk. Wenn Sie an der Blockierung von Aktivitäten< 1 > interessiert sind, können Sie Umbrella 14 Tage kostenlos testen.</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'Vorname',
                    errors: {
                        empty: 'Vorname ist erforderlich.',
                        invalid: 'Der Vorname ist ungültig.',
                    },
                },
                lastName: {
                    label: 'Nachname',
                    errors: {
                        empty: 'Nachname ist erforderlich.',
                        invalid: 'Der Nachname ist ungültig.',
                    },
                },
                emailAddress: {
                    label: 'E-Mail-Adresse',
                    errors: {
                        empty: 'E-Mail-Adresse ist erforderlich.',
                        invalid: 'E-Mail-Adresse ist ungültig.',
                    },
                },
                phoneNumber: {
                    label: 'Telefonnummer',
                    errors: {
                        empty: 'Telefonnummer ist erforderlich.',
                        invalid: 'Telefonnummer ist ungültig.',
                    },
                },
                country: {
                    label: 'Land',
                    errors: {
                        empty: 'Land ist erforderlich.',
                    },
                },
                companyName: {
                    label: 'Name des Unternehmens',
                    errors: {
                        empty: 'Name des Unternehmens ist erforderlich.',
                        invalid: 'Name des Unternehmens ist ungültig.',
                    },
                },
                createPassword: {
                    label: 'Kennwort erstellen',
                    errors: {
                        empty: 'Eintrag in \"Kennwort erstellen\" ist erforderlich.',
                        invalid: 'Eintrag in \"Kennwort erstellen\" ist ungültig.',
                    },
                    rulesMessage: 'Das Kennwort muss mindestens eine Zahl, einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen (z. B. !, @, #) enthalten, darf nicht Teil Ihres Benutzernamens sein und muss mindestens 8 Zeichen lang sein.',
                },
                confirmPassword: {
                    label: 'Passwort bestätigen',
                    errors: {
                        empty: 'Eintrag in \"Kennwort bestätigen\" ist erforderlich.',
                        newPasswordRequired: 'Sie müssen zuerst ein gültiges neues Kennwort eingeben und dieses dann bestätigen.',
                        mismatch: 'Der Eintrag im Feld \"Kennwort bestätigen\" muss mit dem Eintrag im Feld \"Kennwort erstellen\" übereinstimmen.',
                    },
                },
                russiaCheckbox: {
                    label: 'Ich bestätige, dass dieses Konto für geschäftliche Zwecke bestimmt ist.',
                    errors: {
                        invalid: 'Dieses Produkt, dieser Service bzw. die kostenlose Testversion ist für die Nutzung in Russland nicht verfügbar.',
                    },
                },

            },
            buttonLabel: 'DNS-Überwachung jetzt einrichten',
            errorMessage: 'Es ist ein Fehler aufgetreten. Wenn diese E-Mail-Adresse bereits einem Benutzer gehört, <1>melden Sie sich an</1> und senden Sie sie erneut. Beachten Sie auch, dass Sie nur eine DNS-Überwachung pro Unternehmen einrichten können. Andernfalls wenden Sie sich an den <3>Support</3>.',
            optInMessage: 'Ja! Ich möchte Newsletter, Updates und Promotions über Cisco Umbrella, Cisco Cloudlock und zugehörige Angebote erhalten, und ich nehme zur Kenntnis, dass ich meine Präferenzen jederzeit ändern kann.',
            optOutMessage: 'Nein! Ich möchte keine Newsletter, Updates und Promotions über Cisco Umbrella, Cisco Cloudlock und zugehörige Angebote erhalten, und ich nehme zur Kenntnis, dass ich meine Präferenzen jederzeit ändern kann.',
        },
        finePrint: '<0>Indem Sie auf \"DNS-Überwachung jetzt einrichten\" klicken, stimmen Sie den</0> <1>Nutzungsbedingungen</1> <2>und der</2> <3>Datenschutzrichtlinie</3> <4>für Umbrella zu und nehmen davon Kenntnis, dass Ihre persönlichen Informationen zu Verarbeitungszwecken in ein anderes Land übertragen werden können als das Land, in dem Sie Ihren Wohnsitz haben. Die Datenschutzstandards dieses Landes sind möglicherweise unterschiedlich.</4>',
        footer: {
            menu: [
                'Datenschutz',
                'Sitemap',
                'Nutzungsbedingungen',
            ],
        },
    },
};
