export default {
    translation: {
        pageHeading: 'Inscríbase para el monitoreo de DNS',
        header: {
            altText: 'Cisco Umbrella',
        },
        sidebar: {
            heading: 'DNS sumamente confiable, rápido y recursivo para su empresa GRATUITO con visibilidad de seguridad en tiempo real.',
            subheading: 'Comience a trabajar en 30 segundos. No se requieren tarjeta de crédito ni llamada telefónica.',
            list: {
                heading: '¿Qué se incluye?',
                items: [
                    {
                        heading: 'Visibilidad en toda Internet',
                        body: 'En tiempo real, toda la actividad de internet se registra y clasifica por amenaza de seguridad (por ejemplo, malware y suplantación de identidad), así como por 80 tipos de contenido web.',
                    },
                    {
                        heading: 'DNS rápido y confiable',
                        body: 'Desde el 2006, hemos mantenido un tiempo de actividad del 100% y ofrecemos la resolución DNS más rápida que incluye visibilidad en tiempo real e informe de actividad.',
                    },
                    {
                        heading: 'Fácil implementación',
                        body: 'Gracias a la guía de incorporación integrada y a integraciones, la mayoría de las organizaciones implementan el monitoreo de DNS en menos de 30 minutos.',
                    },
                ],
            },
            personal: {
                altText: 'Creación del monitoreo de DNS',
                body: 'El monitoreo de DNS brinda visibilidad de la actividad en la red. Si está interesado en bloquear actividad, < 1 >pruebe Umbrella de forma gratuita durante 14 días</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'Nombre',
                    errors: {
                        empty: 'El campo Nombre es obligatorio.',
                        invalid: 'El valor introducido en el campo Nombre no es válido.',
                    },
                },
                lastName: {
                    label: 'Apellido',
                    errors: {
                        empty: 'El campo Apellido es obligatorio.',
                        invalid: 'El valor introducido en el campo Apellido no es válido.',
                    },
                },
                emailAddress: {
                    label: 'Dirección de correo electrónico',
                    errors: {
                        empty: 'El campo Dirección de correo electrónico es obligatorio.',
                        invalid: 'El valor introducido en el campo Dirección de correo electrónico no es válido.',
                    },
                },
                phoneNumber: {
                    label: 'Número de teléfono',
                    errors: {
                        empty: 'El campo Número de teléfono es obligatorio.',
                        invalid: 'El valor introducido en el campo Número de teléfono no es válido.',
                    },
                },
                country: {
                    label: 'País',
                    errors: {
                        empty: 'El campo País es obligatorio.',
                    },
                },
                companyName: {
                    label: 'Nombre de la empresa',
                    errors: {
                        empty: 'El campo Nombre de la empresa es obligatorio.',
                        invalid: 'El valor introducido en el campo Nombre de la empresa no es válido.',
                    },
                },
                createPassword: {
                    label: 'Crear contraseña',
                    errors: {
                        empty: 'El campo Crear contraseña es obligatorio.',
                        invalid: 'El valor introducido en el campo Crear contraseña no es válido.',
                    },
                    rulesMessage: 'La contraseña debe tener al menos 8 caracteres: un número, una letra minúscula, una letra mayúscula y un carácter especial (como !, @, #); no debe incluir parte de su nombre de usuario.',
                },
                confirmPassword: {
                    label: 'Confirmar contraseña',
                    errors: {
                        empty: 'El campo Confirmar contraseña es obligatorio.',
                        newPasswordRequired: 'Primero debe ingresar una nueva contraseña válida antes de confirmar la contraseña.',
                        mismatch: 'El valor introducido en el campo Confirmar contraseña debe coincidir con el introducido en el campo Crear contraseña.',
                    },
                },
                russiaCheckbox: {
                    label: 'Confirmo que esta cuenta es para fines comerciales.',
                    errors: {
                        invalid: 'Este producto, servicio o prueba gratuita no está disponible para el uso del consumidor en Rusia.',
                    },
                },

            },
            buttonLabel: 'Obtener monitoreo de DNS ahora mismo',
            errorMessage: 'Se ha producido un error. Si este correo electrónico ya pertenece a un usuario, <1>inicie sesión</1> y vuelva a enviarlo. Además, tenga en cuenta que solo puede disponer de una organización de monitoreo de DNS. De lo contrario, póngase en contacto con el servicio de <3>soporte.</3>.',
            optInMessage: 'Sí. Deseo recibir boletines, actualizaciones y promociones sobre Cisco Umbrella, Cisco Cloudlock y ofertas relacionadas, y acepto que puedo cambiar mis preferencias en cualquier momento.',
            optOutMessage: 'No. No deseo recibir boletines, actualizaciones ni promociones sobre Cisco Umbrella, Cisco Cloudlock u ofertas relacionadas, y acepto que puedo cambiar mis preferencias en cualquier momento.',
        },
        finePrint: '<0>Al hacer clic en “Obtener monitoreo de DNS ahora mismo” acepta las</0> <1>condiciones del servicio</1> <2>y</2> <3>la política de privacidad de Umbrella,</3> <4>y comprende que su información personal podrá transferirse para que se procese fuera del país de residencia, donde las normas de protección de datos pueden ser diferentes.</4>',
        footer: {
            menu: [
                'Política de privacidad',
                'Mapa del sitio',
                'Condiciones del servicio',
            ],
        },
    },
};
