import React, { Fragment } from 'react';
import { translate, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import Header from './components/Header';
import FormSidebar from './components/FormSidebar';
import Form from './components/Form';
import Footer from './components/Footer';
import './css/styles.css';


const App = (props) => {
    const t = props.t;
    return (
        <Fragment>
            <Header />

            <div className="container">
                <h1>{t('pageHeading')}</h1>

                <div className="sidebar">
                    <FormSidebar />
                </div>

                <div className="main">
                    <Fragment>
                        <Form />
                        <p className="fine-print">
                            <Trans i18nKey="finePrint">
                                <span>
                                    By clicking “Get DNS Monitoring now” you agree
                                    to the Umbrella
                                    {' '}
                                </span>
                                <a
                                    href="https://www.cisco.com/c/en/us/products/universal-cloud-agreement.html"
                                    target="_blank"
                                    className="ext-link"
                                    data-type="Terms of Service"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Service
                                </a>
                                <span>
                                    {' '}
                                    and
                                    {' '}
                                </span>
                                <a
                                    href="https://www.opendns.com/privacy/"
                                    target="_blank"
                                    className="ext-link"
                                    data-type="Privacy Policy"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                <span>
                                    {' '}
                                    and you understand that your personal information
                                    may be transferred for processing outside your
                                    country of residence, where standards of data
                                    protection may be different.
                                </span>
                            </Trans>
                        </p>
                    </Fragment>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
};

App.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate('translation')(App);
