import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate, Trans } from 'react-i18next';

const FormSidebar = (props) => (
    <Fragment>
        <h2>{props.t('sidebar.heading')}</h2>

        <p className="margin-0 font-size-3 pad-top-2 pad-bottom-6">
            {props.t('sidebar.subheading')}
        </p>

        <h3>{props.t('sidebar.list.heading')}</h3>

        <ul className="whats-included margin-bottom-6">
            <li>
                <i className="i-allowed font-size-4 color-brand" />
                <p>
                    <strong>{props.t('sidebar.list.items.0.heading')}</strong>
                    <br />
                    {props.t('sidebar.list.items.0.body')}
                </p>
            </li>
            <li>
                <i className="i-allowed font-size-4 color-brand" />
                <p>
                    <strong>{props.t('sidebar.list.items.1.heading')}</strong>
                    <br />
                    {props.t('sidebar.list.items.1.body')}
                </p>
            </li>
            <li>
                <i className="i-allowed font-size-4 color-brand" />
                <p>
                    <strong>{props.t('sidebar.list.items.2.heading')}</strong>
                    <br />
                    {props.t('sidebar.list.items.2.body')}
                </p>
            </li>
        </ul>

        <div className="personal">
            <img
                src="/img/building.svg"
                alt={props.t('sidebar.personal.altText')}
                className="personal__icon"
            />
            <p>
                <Trans i18nKey="sidebar.personal.body">
                    DNS Monitoring provides visibility into activity on your network.
                    If you’re interested in blocking activity,
                    <a
                        href="https://signup.umbrella.com/"
                        className="ext-link"
                        data-type="Cisco Prosumer"
                        target="_parent"
                    >
                        try Umbrella for free for 14 days
                    </a>
                    .
                </Trans>
            </p>
        </div>
    </Fragment>
);

FormSidebar.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate('translation')(FormSidebar);

export { FormSidebar as FormSidebarPure };
