/* eslint-disable no-useless-escape */
export default {
    translation: {
        pageHeading: 'Registrati per DNS Monitoring',
        header: {
            altText: 'Cisco Umbrella',
        },
        sidebar: {
            heading: 'DNS ricorsivo GRATUITO, veloce e ultra affidabile con visibilità sulla sicurezza in tempo reale.',
            subheading: 'Si installa in 30 secondi. Non servono né carta di credito né telefonate.',
            list: {
                heading: 'Cosa comprende?',
                items: [
                    {
                        heading: 'Visibilità ad ampio raggio',
                        body: 'Tutte le attività su Internet e 80 tipi di contenuti Web vengono registrati e classificati in tempo reale in base alla minaccia per la sicurezza (malware, phishing, ecc.).',
                    },
                    {
                        heading: 'DNS veloce e affidabile',
                        body: 'È dal 2006 che assicuriamo massima operatività. Siamo il DNS resolver più veloce che include visibilità e reporting delle attività in tempo reale.',
                    },
                    {
                        heading: 'Facile implementazione',
                        body: 'Grazie alla guida introduttiva incorporata e alle integrazioni, l\'implementazione di DNS Monitoring richiede meno di 30 minuti.',
                    },
                ],
            },
            personal: {
                altText: 'DNS-Monitoring Building',
                body: 'DNS Monitoring permette di vedere le attività sulla rete. Se vuoi bloccare le attività sospette, <1>prova Umbrella gratuitamente per 14 giorni</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'Nome',
                    errors: {
                        empty: 'Il campo Nome è obbligatorio.',
                        invalid: 'Il valore del campo Nome non è valido.',
                    },
                },
                lastName: {
                    label: 'Cognome',
                    errors: {
                        empty: 'Il campo Cognome è obbligatorio.',
                        invalid: 'Il valore del campo Cognome non è valido.',
                    },
                },
                emailAddress: {
                    label: 'Indirizzo e-mail',
                    errors: {
                        empty: 'Il campo Indirizzo e-mail è obbligatorio.',
                        invalid: 'Il valore del campo Indirizzo e-mail non è valido.',
                    },
                },
                phoneNumber: {
                    label: 'Numero di telefono',
                    errors: {
                        empty: 'Il campo Numero di telefono è obbligatorio.',
                        invalid: 'Il valore del campo Numero di telefono non è valido.',
                    },
                },
                country: {
                    label: 'Paese',
                    errors: {
                        empty: 'Il campo Paese è obbligatorio.',
                    },
                },
                companyName: {
                    label: 'Nome azienda',
                    errors: {
                        empty: 'Il campo Nome azienda è obbligatorio.',
                        invalid: 'Il valore del campo Nome azienda non è valido.',
                    },
                },
                createPassword: {
                    label: 'Crea password',
                    errors: {
                        empty: 'Il campo Crea password è obbligatorio.',
                        invalid: 'Il valore del campo Crea password non è valido.',
                    },
                    rulesMessage: 'La password deve contenere almeno un numero, una lettera minuscola, una lettera maiuscola e un carattere speciale (ad esempio, !, @, #), non deve contenere parte del nome utente e deve essere lunga almeno 8 caratteri.',
                },
                confirmPassword: {
                    label: 'Conferma password',
                    errors: {
                        empty: 'Il campo Conferma password è obbligatorio.',
                        newPasswordRequired: 'È necessario immettere una nuova password valida prima di confermarla.',
                        mismatch: 'Il valore del campo Conferma password deve essere identico al valore del campo Crea password.',
                    },
                },
                russiaCheckbox: {
                    label: 'Confermo che questo account è riservato a fini aziendali.',
                    errors: {
                        invalid: 'Questo prodotto, servizio o prova gratuita non è disponibile per i clienti in Russia.',
                    },
                },

            },
            buttonLabel: 'Scarica DNS Monitoring ora',
            errorMessage: 'Si è verificato un errore. Se questa e-mail appartiene già a un utente, <1>accedere a</1> e inviare di nuovo. Inoltre, ricordare che è possibile avere solo un DNS Monitoring per azienda. Per altre richieste, contattare il <3>supporto</3>.',
            optInMessage: 'Sì. Desidero ricevere newsletter, aggiornamenti e promozioni relative a Cisco Umbrella, Cisco Cloudlock e alle offerte correlate. So di poter modificare le mie preferenze in qualsiasi momento.',
            optOutMessage: 'No. Non desidero ricevere newsletter, aggiornamenti e promozioni relative a Cisco Umbrella, Cisco Cloudlock e alle offerte correlate. So di poter modificare le mie preferenze in qualsiasi momento.',
        },
        finePrint: '<0>Facendo clic su \"Scarica DNS Monitoring ora\", l\'utente accetta</0> <1>le condizioni del servizio</1> <2>e</2> <3>l\'informativa sulla privacy</3> <4>di Umbrella. Inoltre, l\'utente accetta che i propri dati personali possano essere trasferiti ai fini del trattamento in un paese diverso da quello di residenza, dove potrebbero essere in vigore norme di protezione dei dati diverse.</4>',
        footer: {
            menu: [
                'Informativa sulla privacy',
                'Mappa del sito',
                'Condizioni del servizio',
            ],
        },
    },
};
