/* eslint-disable no-useless-escape */
export default {
    translation: {
        pageHeading: 'Зарегистрируйтесь для выполнения мониторинга DNS',
        header: {
            altText: 'Umbrella от Cisco',
        },
        sidebar: {
            heading: 'Исключительно надежный и быстрый рекурсивный преобразователь DNS-имен корпоративного класса, который доступен БЕСПЛАТНО и имеет функции мониторинга безопасности в режиме реального времени.',
            subheading: 'Запуск в течение 30 секунд. Кредитная карта или звонок по телефону не требуется.',
            list: {
                heading: 'Что входит в комплект',
                items: [
                    {
                        heading: 'Мониторинг в масштабе Интернета',
                        body: 'В режиме реального времени все действия в Интернете регистрируются и классифицируются по типу угроз безопасности (например, вредоносное ПО и фишинг), а также 80 типам веб-контента',
                    },
                    {
                        heading: 'Быстрый и надежный преобразователь DNS-имен',
                        body: 'С 2006 года мы обеспечиваем полностью безотказную работу систем и предлагаем самый быстродействующий преобразователь DNS-имен, который имеет функции мониторинга в реальном времени и составления отчетов об активности',
                    },
                    {
                        heading: 'Простота развертывания',
                        body: 'Благодаря встроенным интеграциям и руководству по началу работы большинство организаций развертывают DNS-решение для мониторинга всего за 30 минут',
                    },
                ],
            },
            personal: {
                altText: 'Формирование решения DNS для проведения мониторинга',
                body: 'Мониторинг DNS предоставляет данные об активности в вашей сети. Если вы заинтересованы в блокировке действий, <1>опробуйте Umbrella бесплатно в течение 14 дней</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'Имя',
                    errors: {
                        empty: 'Поле \"Имя\" обязательно для заполнения.',
                        invalid: 'Недопустимое значение в поле имени.',
                    },
                },
                lastName: {
                    label: 'Фамилия',
                    errors: {
                        empty: 'Поле \"Фамилия\" обязательно для заполнения.',
                        invalid: 'Недопустимое значение в поле фамилии.',
                    },
                },
                emailAddress: {
                    label: 'Адрес электронной почты',
                    errors: {
                        empty: 'Поле \"Адрес электронной почты\" является обязательным для заполнения.',
                        invalid: 'Недопустимое значение в поле адреса электронной почты.',
                    },
                },
                phoneNumber: {
                    label: 'Номер телефона',
                    errors: {
                        empty: 'Поле \"Номер телефона\" является обязательным для заполнения.',
                        invalid: 'Недопустимое значение в поле номера телефона.',
                    },
                },
                country: {
                    label: 'Страна',
                    errors: {
                        empty: 'Поле \"Страна\" является обязательным для заполнения.',
                    },
                },
                companyName: {
                    label: 'Название компании',
                    errors: {
                        empty: 'Поле \"Название компании\" является обязательным для заполнения.',
                        invalid: 'Недопустимое значение в поле \"Название компании\".',
                    },
                },
                createPassword: {
                    label: 'Создать пароль',
                    errors: {
                        empty: 'Поле \"Создать пароль\" является обязательным для заполнения.',
                        invalid: 'Недопустимое значение в поле \"Создать пароль\".',
                    },
                    rulesMessage: 'Пароль должен содержать по крайней мере одну цифру, одну строчную букву, одну заглавную букву и один специальный символ (например, !, @, #). Пароль должен состоять как минимум из 8 символов и не может включать в себя часть имени пользователя.',
                },
                confirmPassword: {
                    label: 'Подтвердить пароль',
                    errors: {
                        empty: 'Поле \"Подтвердить пароль\" является обязательным для заполнения.',
                        newPasswordRequired: 'Перед подтверждением пароля необходимо ввести допустимый новый пароль.',
                        mismatch: 'Значение в поле "Подтвердить пароль" должно совпадать со значением поля \"Создать пароль\".',
                    },
                },
                russiaCheckbox: {
                    label: 'Я подтверждаю, что эта учетная запись предназначена для бизнес-целей.',
                    errors: {
                        invalid: 'Этот продукт, услуга или бесплатная пробная версия недоступны для использования потребителем в России.',
                    },
                },

            },
            buttonLabel: 'Получить средство мониторинга DNS прямо сейчас',
            errorMessage: 'Произошла ошибка. Если этот адрес электронной почты уже принадлежит пользователю, выполните <1>вход в систему</1> и повторите отправку. Также необходимо учитывать, что у вас может быть только одна организация мониторинга DNS. В ином случае обратитесь <3>в службу поддержки.</3>.',
            optInMessage: 'Да! Я хочу получать новости, обновления и специальные предложения, связанные с Cisco Umbrella, Cisco Cloudlock и сопутствующими продуктами. Я понимаю, что могу изменить эти настройки в любой момент.',
            optOutMessage: 'Нет! Я не хочу получать новости, обновления и специальные предложения, связанные с Cisco Umbrella, Cisco Cloudlock и сопутствующими продуктами. Я понимаю, что могу изменить эти настройки в любой момент.',
        },
        finePrint: '<0>Нажимая кнопку \"Получить средство мониторинга DNS прямо сейчас\", вы принимаете</0> <1>условия предоставления услуг</1> <2>и</2> <3>политику конфиденциальности</3> <4> Umbrella и понимаете, что ваша личная информация может быть передана для обработки за пределы страны вашего проживания, где стандарты защиты данных могут отличаться.</4>',
        footer: {
            menu: [
                'Политика конфиденциальности',
                'Карта сайта',
                'Условия предоставления услуг',
            ],
        },
    },
};
