/* eslint-disable no-useless-escape */
export default {
    translation: {
        pageHeading: 'Inscreva-se para o monitoramento de DNS',
        header: {
            altText: 'Umbrella por Cisco',
        },
        sidebar: {
            heading: 'DNS recursivo ultraconfiável e rápido para sua empresa GRATUITAMENTE e com visibilidade de segurança em tempo real.',
            subheading: 'Comece em 30 segundos Não é necessário cartão de crédito ou chamada telefônica',
            list: {
                heading: 'O que está incluído?',
                items: [
                    {
                        heading: 'Visibilidade abrangente da Internet',
                        body: 'Em tempo real, toda a atividade da Internet é registrada e categorizada por ameaça de segurança (como malware e phishing), além de 80 tipos de conteúdo da Web.',
                    },
                    {
                        heading: 'DNS rápido e confiável',
                        body: 'Desde 2006, mantemos 100% de tempo de atividade e solucionamos o DNS de modo mais rápido, incluindo visibilidade em tempo real e relatórios de atividades',
                    },
                    {
                        heading: 'Implantação fácil',
                        body: 'Graças ao guia integrado e integrações, a maioria das organizações implanta o monitoramento de DNS em menos de 30 minutos.',
                    },
                ],
            },
            personal: {
                altText: 'Construção de monitoramento de DNS',
                body: 'O monitoramento de DNS oferece visibilidade à atividade na sua rede. Se você estiver interessado em bloqueio de atividade, <1>experimente o Umbrella gratuitamente por 14 dias</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'Nome',
                    errors: {
                        empty: 'O campo Nome é obrigatório.',
                        invalid: 'A entrada no campo Nome é inválida.',
                    },
                },
                lastName: {
                    label: 'Sobrenome',
                    errors: {
                        empty: 'O campo Sobrenome é obrigatório.',
                        invalid: 'A entrada no campo Sobrenome é inválida.',
                    },
                },
                emailAddress: {
                    label: 'Endereço de e-mail',
                    errors: {
                        empty: 'O campo Endereço de e-mail é obrigatório.',
                        invalid: 'A entrada no campo Endereço de e-mail é inválida.',
                    },
                },
                phoneNumber: {
                    label: 'Número de telefone',
                    errors: {
                        empty: 'O campo Número de telefone é obrigatório.',
                        invalid: 'A entrada no campo Número de telefone é inválida.',
                    },
                },
                country: {
                    label: 'País',
                    errors: {
                        empty: 'O campo País é obrigatório.',
                    },
                },
                companyName: {
                    label: 'Nome da empresa',
                    errors: {
                        empty: 'O campo Nome da empresa é obrigatório.',
                        invalid: 'A entrada no campo Nome da empresa é inválida.',
                    },
                },
                createPassword: {
                    label: 'Criar senha',
                    errors: {
                        empty: 'O campo Criar senha é obrigatório.',
                        invalid: 'A entrada no campo Criar senha é inválida.',
                    },
                    rulesMessage: 'A senha deve conter pelo menos: um número, uma letra minúscula, uma letra maiúscula e um caractere especial (como as !, @, #), não conter parte do nome do usuário e ter pelo menos 8 caracteres.',
                },
                confirmPassword: {
                    label: 'Confirmar senha',
                    errors: {
                        empty: 'O campo Confirmar senha é obrigatório.',
                        newPasswordRequired: 'Antes da senha confirmada, primeiro digite uma nova senha válida.',
                        mismatch: 'O campo Confirmar nova senha deve corresponder ao campo Criar senha.',
                    },
                },
                russiaCheckbox: {
                    label: 'Confirmo que esta conta é para fins comerciais.',
                    errors: {
                        invalid: 'Este produto, serviço ou avaliação gratuita não está disponível para uso do consumidor na Rússia.',
                    },
                },

            },
            buttonLabel: 'Obter o monitoramento de DNS agora',
            errorMessage: 'Houve um erro. Se este e-mail já pertence a um usuário faça login em <1></1> e reenvie. Lembre-se também de que você só pode ter uma empresa de monitoramento de DNS. Caso contrário, acesse o suporte <3></3>.',
            optInMessage: 'Sim! Quero receber boletins informativos, atualizações e promoções sobre o Cisco Umbrella e o Cisco Cloudlock, além de ofertas relacionadas. Entendo que posso alterar minhas preferências a qualquer momento.',
            optOutMessage: 'Não! Não quero receber boletins informativos, atualizações e promoções sobre o Cisco Umbrella e o Cisco Cloudlock, além de ofertas relacionadas. Entendo que posso alterar minhas preferências a qualquer momento.',
        },
        finePrint: '<0>Ao clicar em \"Obter DNS o monitoramento agora\", você concorda com os</0> <1>termos de serviço</1> <2>e a</2> <3>política de privacidade do Umbrella<3></3> <4>e você entende que suas informações pessoais podem ser transferidas para processamento fora de seu país de residência, onde os padrões de proteção de dados podem ser diferentes.</4>',
        footer: {
            menu: [
                'Política de privacidade',
                'Mapa do site',
                'Termos de serviço',
            ],
        },
    },
};
