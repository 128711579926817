import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const Footer = (props) => (
    <footer className="font-size-2">
        <div className="container">
            <p className="copyright">
                &copy; Cisco Umbrella
                {' '}
                {new Date().getFullYear()}
            </p>
            <ul id="menu-bottom-nav" className="terms">
                <li>
                    <a href="https://www.cisco.com/web/siteassets/legal/privacy_full.html">
                        {props.t('footer.menu.0')}
                    </a>
                </li>
                <li>
                    <a href="https://umbrella.cisco.com/sitemap/">{props.t('footer.menu.1')}</a>
                </li>
                <li>
                    <a href="https://www.cisco.com/c/en/us/products/universal-cloud-agreement.html">
                        {props.t('footer.menu.2')}
                    </a>
                </li>
            </ul>
        </div>
    </footer>
);

Footer.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate('translation')(Footer);
