export default {
    translation: {
        pageHeading: 'Sign up for DNS Monitoring',
        header: {
            altText: 'Umbrella by Cisco',
        },
        sidebar: {
            heading: 'Ultra-reliable and fast recursive DNS for your business for FREE with real-time security visibility.',
            subheading: 'Get started in 30 seconds. No credit card or phone call required.',
            list: {
                heading: 'What is included?',
                items: [
                    {
                        heading: 'Internet-Wide Visibility',
                        body: 'In real-time, all internet activity is logged and categorized by security threat (such as malware & phishing) as well as 80 types of web content.',
                    },
                    {
                        heading: 'Fast & Reliable DNS',
                        body: 'Since 2006, we’ve maintained 100% uptime and we’re the fastest DNS resolver that includes real-time visibility and activity reporting.',
                    },
                    {
                        heading: 'Easy Deployment',
                        body: 'Thanks to the built-in onboarding guide and integrations, most organizations deploy DNS Monitoring in under 30 minutes.',
                    },
                ],
            },
            personal: {
                altText: 'DNS-Monitoring Building',
                body: 'DNS Monitoring provides visibility into activity on your network. If you’re interested in blocking activity, <1>try Umbrella for free for 14 days</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'First Name',
                    errors: {
                        empty: 'First Name field is required.',
                        invalid: 'First Name field entry is invalid.',
                    },
                },
                lastName: {
                    label: 'Last Name',
                    errors: {
                        empty: 'Last Name field is required.',
                        invalid: 'Last Name field entry is invalid.',
                    },
                },
                emailAddress: {
                    label: 'Email Address',
                    errors: {
                        empty: 'Email Address field is required.',
                        invalid: 'Email Address field entry is invalid.',
                    },
                },
                phoneNumber: {
                    label: 'Phone Number',
                    errors: {
                        empty: 'Phone Number field is required.',
                        invalid: 'Phone Number field entry is invalid.',
                    },
                },
                country: {
                    label: 'Country',
                    errors: {
                        empty: 'Country field is required.',
                    },
                },
                companyName: {
                    label: 'Company Name',
                    errors: {
                        empty: 'Company Name field is required.',
                        invalid: 'Company Name field entry is invalid.',
                    },
                },
                createPassword: {
                    label: 'Create Password',
                    errors: {
                        empty: 'Create Password field is required.',
                        invalid: 'Create Password field entry is invalid.',
                    },
                    rulesMessage: 'The password must contain at least: one number, one lowercase letter, one capital letter, and one special character (such as !, @, #), not contain part of your username, and be at least 8 characters long.',
                },
                confirmPassword: {
                    label: 'Confirm Password',
                    errors: {
                        empty: 'Confirm Password field is required.',
                        newPasswordRequired: 'You must first enter a valid new password before confirmed password.',
                        mismatch: 'Confirm Password field must match Create Password field.',
                    },
                },
                russiaCheckbox: {
                    label: 'I confirm that this account is for business purposes.',
                    errors: {
                        invalid: 'This product, service, or free trial is not available for consumer use in Russia.',
                    },
                },

            },
            buttonLabel: 'Get DNS Monitoring Now',
            errorMessage: 'There has been an error. If this email already belongs to a  user please <1>login</1> and resubmit. Also keep in mind that you can only have one DNS Monitoring organization. Otherwise, please reach out to <3>support</3>.',
            optInMessage: 'Yes! I want to receive newsletters, updates and promotions about Cisco Umbrella and Cisco Cloudlock, and related offerings, and I understand I can change my preferences at any time.',
            optOutMessage: 'No! I don’t want to receive newsletters, updates and promotions about Cisco Umbrella and Cisco Cloudlock, and related offerings, and I understand I can change my preferences at any time.',
        },
        finePrint: '<0>By clicking “Get DNS Monitoring now” you agree to the Umbrella</0> <1>Terms of Service</1> <2>and</2> <3>Privacy Policy</3> <4>and you understand that your personal information may be transferred for processing outside your country of residence, where standards of data protection may be different.</4>',
        footer: {
            menu: [
                'Privacy Policy',
                'Sitemap',
                'Terms of Service',
            ],
        },
    },
};
