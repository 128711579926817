export const optInList = [
    'AU',
    'AT',
    'BE',
    'BA',
    'BG',
    'CA',
    'CO',
    'HR',
    'CY',
    'CZ',
    'DK',
    'DE',
    'GR',
    'HK',
    'HU',
    'IE',
    'IL',
    'IT',
    'KP',
    'LV',
    'LT',
    'LU',
    'NL',
    'NZ',
    'NO',
    'PH',
    'PL',
    'PT',
    'QA',
    'RO',
    'RU',
    'SK',
    'SI',
    'ES',
    'CH',
    'UY',
    'GB',
];

export const optOutList = [
    'AR',
    'BR',
    'CL',
    'CN',
    'CR',
    'EC',
    'EG',
    'EE',
    'FI',
    'FR',
    'GE',
    'IN',
    'ID',
    'JP',
    'MY',
    'MX',
    'PA',
    'PY',
    'PE',
    'SG',
    'ZA',
    'SE',
    'TW',
    'TH',
    'TR',
    'AE',
    'UA',
    'US',
    'VE',
    'VN',
];

/* export const russiaList = [
    'RU',
] */
