/* eslint-disable no-useless-escape */
export default {
    translation: {
        pageHeading: 'DNS 모니터링에 등록',
        header: {
            altText: 'Cisco에서 제공하는 Umbrella',
        },
        sidebar: {
            heading: '실시간 보안 가시성을 통해 비즈니스에 무료로 이용할 수 있는 매우 안정적이고 빠른 재귀적 DNS.',
            subheading: '30초 안에 시작됩니다. 신용카드나 전화 통화가 필요 없습니다.',
            list: {
                heading: '내용 소개',
                items: [
                    {
                        heading: '인터넷 전체 가시성',
                        body: '실시간으로 모든 인터넷 활동이 로깅되며 보안 위협(예: 맬웨어 및 피싱) 또는 80가지 유형의 웹 콘텐츠로 분류됩니다.',
                    },
                    {
                        heading: '빠르고 안정적인 DNS',
                        body: '저희는 2006년 이후 100%의 업타임을 유지해왔으며 실시간 가시성 및 활동 보고를 포함하는 가장 빠른 DNS 확인자입니다.',
                    },
                    {
                        heading: '간편한 구축',
                        body: '내장된 온보딩 가이드 및 통합 덕분에 대부분의 조직에서는 DNS 모니터링을 30분도 되지 않아 구축합니다.',
                    },
                ],
            },
            personal: {
                altText: 'DNS 모니터링 구축',
                body: 'DNS 모니터링에서는 네트워크에서 이루어지는 활동에 대한 가시성을 제공합니다. 차단 활동에 관심이 있다면 <1>Umbrella를 14일 동안 무료로 사용해보십시오.</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: '이름',
                    errors: {
                        empty: '이름 필드는 필수 항목입니다.',
                        invalid: '이름 필드 항목이 잘못되었습니다.',
                    },
                },
                lastName: {
                    label: '성',
                    errors: {
                        empty: '성 필드는 필수 항목입니다.',
                        invalid: '성 필드 항목이 잘못되었습니다.',
                    },
                },
                emailAddress: {
                    label: '이메일 주소',
                    errors: {
                        empty: '이메일 주소 필드는 필수 항목입니다.',
                        invalid: '이메일 주소 필드 항목이 잘못되었습니다.',
                    },
                },
                phoneNumber: {
                    label: '전화 번호',
                    errors: {
                        empty: '전화번호 필드는 필수 항목입니다.',
                        invalid: '전화번호 필드 항목이 잘못되었습니다.',
                    },
                },
                country: {
                    label: '국가',
                    errors: {
                        empty: '국가 필드는 필수 항목입니다.',
                    },
                },
                companyName: {
                    label: '회사 이름',
                    errors: {
                        empty: '회사 이름 필드는 필수 항목입니다.',
                        invalid: '회사 이름 필드 항목이 잘못되었습니다.',
                    },
                },
                createPassword: {
                    label: '비밀번호 생성',
                    errors: {
                        empty: '비밀번호 생성 필드는 필수 항목입니다.',
                        invalid: '비밀번호 생성 필드 항목이 잘못되었습니다.',
                    },
                    rulesMessage: '비밀번호에는 최소한 숫자 1개, 소문자 1개, 대문자 1개, 특수 문자 1개( !, @, # 등)가 포함되어야 하고, 사용자 이름의 일부는 포함되지 않아야 하며 최소 8자여야 합니다.',
                },
                confirmPassword: {
                    label: '비밀번호 확인',
                    errors: {
                        empty: '비밀번호 확인 필드는 필수 항목입니다.',
                        newPasswordRequired: '확인된 비밀번호 전에 먼저 유효한 새 비밀번호를 입력해야 합니다.',
                        mismatch: 'Confirm Password(비밀번호 확인) 필드는 Create Password(비밀번호 생성) 필드와 일치해야 합니다.',
                    },
                },
                russiaCheckbox: {
                    label: '이 어카운트는 비즈니스 목적임을 확인합니다.',
                    errors: {
                        invalid: '이 제품, 서비스 또는 무료 평가판은 러시아의 소비자가 사용할 수 없습니다.',
                    },
                },

            },
            buttonLabel: '지금 DNS 모니터링 가져오기',
            errorMessage: '오류가 발생했습니다. 이 이메일이 이미 사용자의 것이라면 <1>로그인하여</1> 다시 제출하십시오. 또한 DNS 모니터링 조직은 하나이어야 한다는 점에 유의하십시오. 그렇지 않다면 <3>Cisco 지원에 문의하십시오.</3>.',
            optInMessage: '예! Cisco Umbrella 및 Cisco Cloudlock, 관련 제안에 대한 뉴스레터, 업데이트, 프로모션을 수신하기를 원하며, 언제라도 환경설정을 변경할 수 있다는 점을 알고 있습니다.',
            optOutMessage: '아니오! Cisco Umbrella 및 Cisco Cloudlock, 관련 제안에 대한 뉴스레터, 업데이트, 프로모션 수신을 원치 않으며, 언제라도 환경설정을 변경할 수 있다는 점을 알고 있습니다.',
        },
        finePrint: '<0>\"지금 DNS 모니터링 가져오기\"를 클릭하면 Umbrella</0> <1> 서비스 약관</1> <2> 및</2> <3> 개인정보 보호정책에 동의하는 것입니다.</3> <4>또한 귀하의 개인 정보가 거주국 외부로 전송되어 처리될 수 있으며, 해당 국가의 데이터 보호 기준이 다를 수 있음을 이해합니다.</4>',
        footer: {
            menu: [
                '개인정보 보호정책',
                '사이트맵',
                '서비스 약관',
            ],
        },
    },
};
