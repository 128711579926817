/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import { get, post } from 'axios';
import TextInput from '@opendns/dpl-text-input';
import Button from '@opendns/dpl-button';
import Checkbox from '@opendns/dpl-checkbox';
import Dropdown from '@opendns/dpl-dropdown';
import Spinner from '@opendns/dpl-spinner';
import { CancelIcon } from '@opendns/svg-icons';
import { translate, Trans } from 'react-i18next';
import {
    optInList,
    optOutList,
} from '../utils/opt-lists';

class Form extends React.Component {
    state = {
        selectedCountry: '',
        countries: [],
        submitting: false,
        formError: false,
        passwordFocused: false,
        optInput: {
            emailOptIn: false,
            emailOptOut: false,
        },
        showOptIn: false,
        showOptOut: false,
        inputDataValidatedGood: false,
        firstName: '',
        validFirstName: false,
        errMessageFirstName: '',
        lastName: '',
        validLastName: false,
        errMessageLastName: '',
        emailAddress: '',
        validEmailAddress: false,
        errMessageEmailAddress: '',
        phoneNumber: '',
        validPhoneNumber: false,
        errMessagePhoneNumber: '',
        companyName: '',
        validCompanyName: false,
        errMessageCompanyName: '',
        createPassword: '',
        validCreatePassword: false,
        errMessageCreatePassword: '',
        confirmPassword: '',
        validConfirmPassword: false,
        errMessageConfirmPassword: '',
        confirmedRussianBusiness: false,
    }

    passwordRef = React.createRef();

    passwordHelperRef = React.createRef();

    regexes = {
        firstName: '^[A-Za-z\xc0-\xff\s\'-]+$',
        lastName: '^[A-Za-z\xc0-\xff\s\'-]+$',
        emailAddress: '^[A-Za-z0-9\.\-_\+]+@[A-Za-z0-9\.\-_]+\.[A-Za-z]+$',
        phoneNumber: '^[+\\d\)\(\-]+$',
        companyName: '^[A-Za-z0-9\xc0-\xff\s\'\*\!\-\,\.\&]+$',
    };

    regexesPassword = {
        createPassword: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,256}$',
        confirmPassword: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,256}$',
    };

    componentWillMount = () => {
        this.getCountryInfo();
    }

    componentDidMount = () => {
        this.updatePasswordHelper();
    }

    componentDidUpdate = () => {
        this.updatePasswordHelper();
    }

    chooseEmailOptIn = () => {
        this.setState(prevState => ({
            optInput: Object.assign({}, prevState.optInput, {
                emailOptIn: !prevState.optInput.emailOptIn,
            }),
        }));
    };

    chooseEmailOptOut = () => {
        this.setState(prevState => ({
            optInput: Object.assign({}, prevState.optInput, {
                emailOptOut: !prevState.optInput.emailOptOut,
            }),
        }));
    };

    toggleConfirmRussianBusiness = () => {
        this.setState(prevState => ({
            confirmedRussianBusiness: !prevState.confirmedRussianBusiness,
        }));
    }

    postData = () => {
        let canEmail = true;

        if (this.state.showOptIn) {
            canEmail = this.state.optInput.emailOptIn;
        }

        if (this.state.showOptOut) {
            canEmail = !this.state.optInput.emailOptOut;
        }

        const inputData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.phoneNumber,
            countryId: this.state.selectedCountry,
            companyName: this.state.companyName,
            password: this.state.createPassword,
            canEmail,
            l10nCode: window.navigator.language,
        };
        post('/api/create', inputData)
            .then(({ data }) => {
                if (data.status === 'success') {
                    // redirect to dashboard
                    window.location = data.dashboardLink;
                } else {
                    this.setState({
                        submitting: false,
                        formError: true,
                    });
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                this.setState({
                    submitting: false,
                    formError: true,
                });
            });
    }

    selectCountry = ({ value }) => {
        this.setState({
            selectedCountry: value,
            showOptIn: optInList.indexOf(value) > -1,
            showOptOut: optOutList.indexOf(value) > -1,
        });
    };

    updatePasswordHelper = () => {
        if (this.passwordHelperRef.current) {
            const passwordOffset = this.passwordRef.current.offsetTop;
            this.passwordHelperRef.current.style.top = `${passwordOffset - 110}px`;
        }
    }

    collectTextValue = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    getCountryInfo = () => {
        get('/api/countries')
            .then(({ data }) => {
                const countryValues = Object.keys(data.countries).map(countryCode => ({
                    value: countryCode,
                    label: data.countries[countryCode],
                }));

                this.setState({ countries: countryValues });
            })
            .catch(error => {
                console.log('Error getting country list:', error);
            });
    }

    validateInputField = (name) => {
        const stateNameValue = this.state[name];
        const camelCaseName = name.charAt(0).toUpperCase() + name.slice(1);
        const stateErrMsgName = `errMessage${camelCaseName}`;
        const stateValidationName = `valid${camelCaseName}`;

        if (stateNameValue === '' && this.state[stateValidationName] === false) {
            return;
        }

        const regex = new RegExp(this.regexes[name]);
        const errMsg = regex.test(stateNameValue) ? '' : 'invalid';

        this.setState({
            [stateErrMsgName]: errMsg,
            [stateValidationName]: errMsg === '',
        });
    };

    allFieldsValid = () => {
        if (this.state.selectedCountry === 'RU' && this.state.confirmedRussianBusiness === false) {
            return false;
        }
        return (this.state.validFirstName
                && this.state.validLastName
                && this.state.validEmailAddress
                && this.state.validPhoneNumber
                && this.state.validCompanyName
                && this.state.validCreatePassword
                && this.state.validConfirmPassword
                && this.state.selectedCountry !== ''
        );
    };

    inputDataValidatedGood = () => {
        const inputFields = Object.keys(this.regexes);
        const results = {};
        inputFields.forEach(field => {
            results[field] = this.validateInputField(field);
        });
        return results;
    };

    validateCreatePassword = () => {
        const create = this.state.createPassword;

        if (create === '' && this.state.validCreatePassword === false && this.state.validConfirmPassword === false) {
            return;
        }
        if (this.state.validConfirmPassword === true
            && this.state.confirmPassword !== this.state.createPassword) {
            this.setState({
                errMessageConfirmPassword: 'mismatch',
                validConfirmPassword: false,
            });
        }
        if (this.state.confirmPassword === this.state.createPassword
            && this.state.confirmPassword !== '' && this.state.createPassword !== '') {
            this.setState({
                errMessageConfirmPassword: '',
                validConfirmPassword: true,
            });
        }
        const regex = new RegExp(this.regexesPassword.createPassword);
        const errMsg = regex.test(create) ? '' : 'invalid';

        this.setState({
            errMessageCreatePassword: errMsg,
            validCreatePassword: errMsg === '',
        });
    };

    validateConfirmPassword = () => {
        if (this.state.confirmPassword === '' && this.state.validConfirmPassword === false) {
            return;
        }

        let errMsg = '';
        if (!this.state.validCreatePassword) {
            errMsg = 'newPasswordRequired';
        } else if (this.state.confirmPassword !== this.state.createPassword) {
            errMsg = 'mismatch';
        }

        this.setState({
            errMessageConfirmPassword: errMsg,
            validConfirmPassword: errMsg === '',
        });
    };

    render = () => {
        const t = this.props.t;

        return (
            <form
                style={{ position: 'relative' }}
                name="theform"
                className="pad-bottom-4"
            >
                <label className="margin-bottom-2 display-block color-p2" htmlFor="first-name">
                    <span className="font-size-2 bold">{t('form.fields.firstName.label')}</span>
                    <TextInput
                        id="first-name"
                        type="text"
                        isError={this.state.errMessageFirstName !== ''}
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.collectTextValue}
                        onBlur={() => this.validateInputField('firstName')}
                    />
                    {
                        (this.state.errMessageFirstName !== '') && (
                            <div className="margin-vertical-2 flex-layout flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.firstName.errors.${this.state.errMessageFirstName}`)}</span>
                            </div>
                        )
                    }
                </label>

                <label className="margin-bottom-2 display-block color-p2" htmlFor="last-name">
                    <span className="font-size-2 bold">{t('form.fields.lastName.label')}</span>
                    <TextInput
                        id="last-name"
                        type="text"
                        name="lastName"
                        isError={this.state.errMessageLastName !== ''}
                        value={this.state.lastName}
                        onChange={this.collectTextValue}
                        onBlur={() => this.validateInputField('lastName')}
                    />
                    {
                        (this.state.errMessageLastName !== '') && (
                            <div className="margin-vertical-2 flex-layout flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.lastName.errors.${this.state.errMessageLastName}`)}</span>
                            </div>
                        )
                    }
                </label>

                <label className="margin-bottom-2 display-block color-p2" htmlFor="email-address">
                    <span className="font-size-2 bold">{t('form.fields.emailAddress.label')}</span>
                    <TextInput
                        id="email-address"
                        type="text"
                        name="emailAddress"
                        autoComplete="email"
                        isError={this.state.errMessageEmailAddress !== ''}
                        value={this.state.emailAddress}
                        onChange={this.collectTextValue}
                        onBlur={() => this.validateInputField('emailAddress')}
                    />
                    {
                        (this.state.errMessageEmailAddress !== '') && (
                            <div className="margin-vertical-2 flex-layout flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.emailAddress.errors.${this.state.errMessageEmailAddress}`)}</span>
                            </div>
                        )
                    }
                </label>

                <label className="margin-bottom-2 display-block color-p2" htmlFor="phone-number">
                    <span className="font-size-2 bold">{t('form.fields.phoneNumber.label')}</span>
                    <TextInput
                        id="phone-number"
                        type="text"
                        name="phoneNumber"
                        isError={this.state.errMessagePhoneNumber !== ''}
                        value={this.state.phoneNumber}
                        onChange={this.collectTextValue}
                        onBlur={() => this.validateInputField('phoneNumber')}
                    />
                    {
                        (this.state.errMessagePhoneNumber !== '') && (
                            <div className="margin-vertical-2 flex-layout flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.phoneNumber.errors.${this.state.errMessagePhoneNumber}`)}</span>
                            </div>
                        )
                    }
                </label>

                <label className="margin-bottom-2 display-block color-p2" htmlFor="dropdown">
                    <p className="margin-top-0 font-size-2 bold" style={{ marginBottom: '4px' }}>{t('form.fields.country.label')}</p>
                    <Dropdown
                        id="dropdown"
                        list={this.state.countries}
                        onSelect={this.selectCountry}
                        // isErrored={this.state.errors.countries !== ''}
                    />
                    {/* {
                        this.state.errors.countries && (
                            <div className="margin-vertical-2 flex-layout
                            flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t('form.fields.country.errors.empty')}</span>
                            </div>
                        )
                    } */}
                </label>

                {this.state.selectedCountry === 'RU' && (
                    <div className="check-row margin-bottom-2">
                        {this.state.confirmedRussianBusiness === false && (
                            <div className="margin-vertical-2 flex-layout row-nowrap flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t('form.fields.russiaCheckbox.errors.invalid')}</span>
                            </div>
                        )}
                        <Checkbox
                            checked={this.state.confirmedRussianBusiness}
                            onClick={this.toggleConfirmRussianBusiness}
                            label={t('form.fields.russiaCheckbox.label')}
                            id="russia-out"
                            name="russia-out"
                        />

                    </div>
                )}

                <label className="margin-bottom-2 display-block color-p2" htmlFor="company-name">
                    <span className="font-size-2 bold">{t('form.fields.companyName.label')}</span>
                    <TextInput
                        id="company-name"
                        type="text"
                        name="companyName"
                        isError={this.state.errMessageCompanyName !== ''}
                        value={this.state.companyName}
                        onChange={this.collectTextValue}
                        onBlur={() => this.validateInputField('companyName')}
                    />
                    {
                        (this.state.errMessageCompanyName !== '') && (
                            <div className="margin-vertical-2 flex-layout flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.companyName.errors.${this.state.errMessageCompanyName}`)}</span>
                            </div>
                        )
                    }
                </label>

                {this.state.passwordFocused && (
                    <div
                        className="bg-p1 pad-3 color-p6 shadow-1"
                        style={{
                            position: 'absolute',
                            left: 0,
                            zIndex: 1,
                        }}
                        ref={this.passwordHelperRef}
                    >
                        {t('form.fields.createPassword.rulesMessage')}
                    </div>
                )}

                <label
                    className="margin-bottom-2 display-block color-p2"
                    ref={this.passwordRef}
                    htmlFor="create-password"
                >
                    <span className="font-size-2 bold">{t('form.fields.createPassword.label')}</span>
                    <TextInput
                        id="create-password"
                        type="password"
                        name="createPassword"
                        autoComplete="new-password"
                        isError={this.state.errMessageCreatePassword !== ''}
                        value={this.state.createPassword}
                        onFocus={() => {
                            this.setState({ passwordFocused: true });
                        }}
                        onChange={this.collectTextValue}
                        /* onBlur={() => {
                            this.setState({ passwordFocused: false });
                        }} */
                        onBlur={() => {
                            this.setState({ passwordFocused: false });
                            this.validateCreatePassword();
                        }}
                    />
                    {
                        (this.state.errMessageCreatePassword !== '') && (
                            <div className="margin-vertical-2 flex-layout flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.createPassword.errors.${this.state.errMessageCreatePassword}`)}</span>
                            </div>
                        )
                    }
                </label>

                <label className="margin-bottom-2 display-block color-p2" htmlFor="confirm-password">
                    <span className="font-size-2 bold">{t('form.fields.confirmPassword.label')}</span>
                    <TextInput
                        id="confirm-password"
                        type="password"
                        name="confirmPassword"
                        autoComplete="new-password"
                        isError={this.state.errMessageConfirmPassword !== ''}
                        value={this.state.confirmPassword}
                        onChange={this.collectTextValue}
                        onBlur={() => this.validateConfirmPassword()}
                    />
                    {
                        (this.state.errMessageConfirmPassword !== '') && (
                            <div className="margin-vertical-2 flex-layout row-nowrap flex-justify-start font-size-2 color-danger">
                                <span className="margin-right-1">
                                    <CancelIcon color="danger" size={16} />
                                </span>
                                <span>{t(`form.fields.confirmPassword.errors.${this.state.errMessageConfirmPassword}`)}</span>
                            </div>
                        )
                    }
                </label>

                {this.state.showOptIn && (
                    <div className="check-row margin-bottom-2">
                        <Checkbox
                            checked={this.state.optInput.emailOptIn}
                            onClick={this.chooseEmailOptIn}
                            label={t('form.optInMessage')}
                            id="opt-in"
                            name="opt-in"
                        />
                    </div>
                )}

                {this.state.showOptOut && (
                    <div className="check-row margin-bottom-2">
                        <Checkbox
                            checked={this.state.optInput.emailOptOut}
                            onClick={this.chooseEmailOptOut}
                            label={t('form.optOutMessage')}
                            id="opt-out"
                            name="opt-out"
                        />
                    </div>
                )}

                {this.state.formError && (
                    <div
                        className={[
                            'message-area',
                            'color-p2',
                            'font-size-2',
                            'margin-bottom-2',
                            'border-1',
                            'border-color-danger',
                            'danger-message',
                        ].join(' ')}
                    >
                        <div
                            className={[
                                'bg-danger',
                                'message-icon',
                                'pad-1',
                            ].join(' ')}
                        >
                            <div className="icon icon--small icon--cancel-inverted" />
                        </div>
                        <div className="message-text pad-1">
                            <span>
                                <Trans i18nKey="form.errorMessage">
                                    There has been an error. If this email
                                    already belongs to a user please
                                    <a href="https://login.umbrella.com" target="_blank" rel="noopener noreferrer">login</a>
                                    {' '}
                                    and resubmit. Also keep in mind that you
                                    can only have one DNS Monitoring organization.
                                    Otherwise, please reach out to
                                    <a href="mailto:umbrella-support@cisco.com">support</a>
                                    .
                                </Trans>
                            </span>
                        </div>
                    </div>
                )}

                <div className="button-container">
                    <Button
                        onClick={this.postData}
                        disabled={!(this.allFieldsValid())}
                    >
                        <span
                            className={`${this.state.submitting ? 'color-brand opacity-half' : ''}`}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {t('form.buttonLabel')}
                        </span>
                        {this.state.submitting && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                }}
                            >
                                <Spinner color="p6" />
                            </div>
                        )}
                    </Button>
                </div>
            </form>
        );
    }
}

Form.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate('translation')(Form);
export { Form as FormPure };
