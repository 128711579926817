import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const Header = (props) => (
    <header className="pad-vertical-5 border-bottom-1 border-color-p3 margin-bottom-7">
        <div className="container">
            <a
                href="https://umbrella.cisco.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src="/img/Cisco-Umbrella-Logo.svg"
                    alt={props.t('header.altText')}
                    className="header-logo"
                />
            </a>
        </div>
    </header>
);

Header.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate('translation')(Header);

export { Header as HeaderPure };
