export default {
    translation: {
        pageHeading: 'Inscrivez-vous à Cisco DNS Monitoring',
        header: {
            altText: 'Cisco Umbrella',
        },
        sidebar: {
            heading: 'Bénéficiez GRATUITEMENT d\'un service DNS rapide, récursif et ultrafiable pour votre entreprise, avec une visibilité en temps réel sur la sécurité.',
            subheading: 'Lancez-vous en 30 secondes. Sans carte bancaire ni appel téléphonique.',
            list: {
                heading: 'Ce qui est inclus',
                items: [
                    {
                        heading: 'Une visibilité à l\'échelle d\'Internet',
                        body: 'Toutes les activités en ligne sont consignées en temps réel et classées par menace (par exemple, malwares et phishing) ainsi qu\'en fonction de 80 types de contenus web.',
                    },
                    {
                        heading: 'Un service DNS rapide et fiable',
                        body: 'Depuis 2006, nous offrons une disponibilité de 100 %. De plus, notre service de résolution DNS est le plus rapide du marché qui inclut une visibilité en temps réel et la création de rapports d\'activité.',
                    },
                    {
                        heading: 'Un déploiement en toute simplicité',
                        body: 'Grâce au guide de déploiement et aux intégrations, la plupart des entreprises déploient Cisco DNS Monitoring en moins de 30 minutes.',
                    },
                ],
            },
            personal: {
                altText: 'Offre Cisco DNS Monitoring',
                body: 'Cisco DNS Monitoring vous offre une visibilité sur l\'activité de votre réseau. Si vous cherchez une fonction pour bloquer les activités malveillantes, <1>essayez gratuitement Cisco Umbrella pendant 14 jours</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: 'Prénom',
                    errors: {
                        empty: 'Le champ Prénom est obligatoire.',
                        invalid: 'Le prénom saisi n\'est pas valide.',
                    },
                },
                lastName: {
                    label: 'Nom',
                    errors: {
                        empty: 'Le champ Nom est obligatoire.',
                        invalid: 'Le nom saisi n\'est pas valide.',
                    },
                },
                emailAddress: {
                    label: 'Adresse e-mail',
                    errors: {
                        empty: 'Le champ Adresse e-mail est obligatoire.',
                        invalid: 'L\'adresse e-mail saisie n\'est pas valide.',
                    },
                },
                phoneNumber: {
                    label: 'N° de téléphone',
                    errors: {
                        empty: 'Le champ N° de téléphone est obligatoire.',
                        invalid: 'Le n° de téléphone saisi n\'est pas valide.',
                    },
                },
                country: {
                    label: 'Pays',
                    errors: {
                        empty: 'Le champ Pays est obligatoire.',
                    },
                },
                companyName: {
                    label: 'Nom de l\'entreprise',
                    errors: {
                        empty: 'Le champ Nom de l\'entreprise est obligatoire.',
                        invalid: 'Le nom d\'entreprise saisi n\'est pas valide.',
                    },
                },
                createPassword: {
                    label: 'Créer un mot de passe',
                    errors: {
                        empty: 'Le champ Créer un mot de passe est obligatoire.',
                        invalid: 'Le mot de passe saisi n\'est pas valide.',
                    },
                    rulesMessage: 'Le mot de passe doit contenir au moins : un numéro, une lettre minuscule, une lettre majuscule et un caractère spécial (par exemple : !, @, #). Il ne doit pas comporter de partie de votre nom d\'utilisateur et doit contenir au moins 8 caractères.',
                },
                confirmPassword: {
                    label: 'Confirmer le mot de passe',
                    errors: {
                        empty: 'Le champ Confirmer le mot de passe est obligatoire.',
                        newPasswordRequired: 'Vous devez d\'abord saisir un nouveau mot de passe valide avant de pouvoir le confirmer.',
                        mismatch: 'Le texte saisi dans le champ de confirmation du mot de passe doit correspondre à celui du champ de création du mot de passe.',
                    },
                },
                russiaCheckbox: {
                    label: 'Je confirme que ce compte est destiné à une activité professionnelle.',
                    errors: {
                        invalid: 'Ce produit, ce service ou cet essai gratuit n\'est pas disponible pour une utilisation grand public en Russie.',
                    },
                },

            },
            buttonLabel: 'Obtenir Cisco DNS Monitoring',
            errorMessage: 'Une erreur s\'est produite. Si cette adresse e-mail appartient déjà à un utilisateur, <1>connectez-vous</1> et renvoyez votre demande. N\'oubliez pas que vous ne pouvez spécifier qu\'une seule entreprise pour Cisco DNS Monitoring. Sinon, veuillez contacter le service <3>d\'assistance</3>.',
            optInMessage: 'Oui ! Je souhaite recevoir les newsletters, les actualités et les promotions relatives à Cisco Umbrella, à Cisco Cloudlock et aux offres liées, et je peux modifier mes préférences à tout moment.',
            optOutMessage: 'Non ! Je ne souhaite pas recevoir les newsletters, les actualités et les promotions relatives à Cisco Umbrella, à Cisco Cloudlock et aux offres liées, et je peux modifier mes préférences à tout moment.',
        },
        finePrint: '<0>En cliquant sur « Obtenir Cisco DNS Monitoring » vous acceptez les</0> <1>Conditions d\'utilisation</1> <2>et</2> <3>la Politique de confidentialité de Cisco Umbrella</3> <4>et que vos informations personnelles soient transmises pour traitement dans un pays autre que celui dans lequel vous résidez, où les règles en matière de protection des données peuvent être différents.</4>',
        footer: {
            menu: [
                'Politique de confidentialité',
                'Plan du site',
                'Conditions d\'utilisation',
            ],
        },
    },
};
