import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import en from './languages/en';
import ru from './languages/ru';
import zhTW from './languages/zh-TW';
import ptBR from './languages/pt-BR';
import esXL from './languages/es-XL';
import it from './languages/it';
import de from './languages/de';
import ko from './languages/ko';
import fr from './languages/fr';
import ja from './languages/ja';

i18next
    .use(LngDetector)
    .init({
        order: ['navigator'], // window.navigator.languages
        whitelist: ['en', 'ru', 'zh', 'pt', 'es', 'it', 'de', 'ko', 'fr', 'ja'],
        nonExplicitWhitelist: true,
        fallbackLng: {
            default: ['en'],
        },
        debug: window.location.hostname.indexOf('umbrella.com') === -1,
        resources: {
            en,
            ru,
            zh: zhTW,
            pt: ptBR,
            es: esXL,
            it,
            de,
            ko,
            fr,
            ja,
        },
        load: 'languageOnly',
        detection: { order: ['navigator'] },
    });

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
    document.getElementById('app')
);
