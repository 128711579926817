export default {
    translation: {
        pageHeading: '註冊 DNS 監控',
        header: {
            altText: '由 Cisco 推出的資安防護傘 - Umbrella',
        },
        sidebar: {
            heading: '免費取得適合您企業使用、超級可靠又快速的遞迴 DNS，以及即時安全性能見度。',
            subheading: '在 30 秒內即可開始使用。不需要使用信用卡或撥打電話。',
            list: {
                heading: '內容包含什麼？',
                items: [
                    {
                        heading: '整個網際網路的能見度',
                        body: '所有網際網路活動皆能即時記錄，並按照安全威脅（例如惡意軟體與網路釣魚）加以分類，並提供 80 種類型的 Web 內容。',
                    },
                    {
                        heading: '快速又可靠的 DNS ',
                        body: '自 2006 年以來，我們始終維持 100% 的正常運作時間，並且以包含即時能見度與活動報告功能之最快速 DNS 解析器自居。',
                    },
                    {
                        heading: '容易部署',
                        body: '由於內建輔導指南與整合功能，大多數的組織皆能在 30 分鐘內部署 DNS 監控功能。',
                    },
                ],
            },
            personal: {
                altText: 'DNS 監控建置',
                body: 'DNS 監控提供了您網路活動的能見度。若您對於封鎖活動感興趣，<1>可免費試用 Umbrella 14 天</1>.',
            },
        },
        form: {
            fields: {
                firstName: {
                    label: '名字',
                    errors: {
                        empty: '「名字」欄為必填項目。',
                        invalid: '「名字」欄輸入內容無效。',
                    },
                },
                lastName: {
                    label: '姓氏',
                    errors: {
                        empty: '「姓氏」欄為必填項目。',
                        invalid: '「姓氏」欄輸入內容無效。',
                    },
                },
                emailAddress: {
                    label: '電子郵件地址',
                    errors: {
                        empty: '「電子郵件地址」欄為必填項目。',
                        invalid: '「電子郵件地址」欄輸入內容無效。',
                    },
                },
                phoneNumber: {
                    label: '電話號碼',
                    errors: {
                        empty: '「電話號碼」欄為必填項目。',
                        invalid: '「電話號碼」欄輸入內容無效。',
                    },
                },
                country: {
                    label: '國家',
                    errors: {
                        empty: '「國家/地區」欄為必填項目。',
                    },
                },
                companyName: {
                    label: '公司名稱',
                    errors: {
                        empty: '「公司名稱」欄為必填項目。',
                        invalid: '「公司名稱」欄輸入內容無效。',
                    },
                },
                createPassword: {
                    label: '建立密碼',
                    errors: {
                        empty: '「建立密碼」欄為必填項目。',
                        invalid: '「建立密碼 」欄輸入內容無效。',
                    },
                    rulesMessage: '密碼至少必須包含：一個數字、一個小寫字母、一個大寫字母以及一個特殊字元（例如 !、@、#），而且不得含有您的使用者名稱的任何部分，並且長度少為 8 個字元。',
                },
                confirmPassword: {
                    label: '確認密碼',
                    errors: {
                        empty: '「確認密碼」欄為必填項目。',
                        newPasswordRequired: '您必須先輸入有效的新密碼，然後再確認密碼。',
                        mismatch: '「確認密碼」欄必須與「建立密碼」欄相符。',
                    },
                },
                russiaCheckbox: {
                    label: '我確認此帳戶將用於商業目的。',
                    errors: {
                        invalid: '此產品、服務或免費試用方案均不適用於俄國的消費者。',
                    },
                },

            },
            buttonLabel: '立刻取得 DNS 監控功能',
            errorMessage: '已經發生錯誤。如果此電子郵件已經屬於某位使用者，請<1>先登入，</1>然後重新提交。另請注意，您僅能擁有一個 DNS 監控組織。否則，請聯絡<3>支援部門</3>.',
            optInMessage: '是的 ！我希望收到有關 Cisco Umbrella 和 Cisco Cloudlock 及相關產品的電子報、更新和促銷活動資訊，而我瞭解我可以隨時變更偏好設定。',
            optOutMessage: '不！我不希望收到有關 Cisco Umbrella 和 Cisco Cloudlock 以及相關產品的電子報、更新和促銷活動資訊，而我瞭解我可以隨時變更偏好設定。',
        },
        finePrint: '<0>只要按下「立刻取得 DNS 監控」，即表示您同意 Umbrella 的</0><1>服務條款</1><2>與</2><3>隱私權政策</3><4>您瞭解自己的資訊可能因處理關係而轉移至您居住的國家/地區以外，而且該處的資料保護標準可能有所不同。</4>',
        footer: {
            menu: [
                '隱私權政策',
                '網站地圖',
                '服務條款',
            ],
        },
    },
};
